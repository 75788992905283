import React, { useContext, useEffect, useState } from "react";
import { db } from "src/firebase";
import { fetchQueries, fetchQueriesAfter } from "src/utils/firebase-utils/firestore-funcs";
import { useAuth } from "./auth-context";

const QueryContext = React.createContext({});
export const useQueryContext = () => useContext(QueryContext);

export default function QueryProvider(props) {
  const [queries, setQueries] = useState(null);
  const [isQueryLoading, setQueryLoading] = useState(true);
  const { currentUser } = useAuth();

  async function fetchInitialQueries() {
    setQueryLoading(true);
    const top40Queries = await fetchQueries(40);
    setQueries([...top40Queries]);
    setQueryLoading(false);
  }
  async function fetchNextQueries() {
    let lastQuery = queries[queries.length - 1];
    const next20queries = await fetchQueriesAfter(20, lastQuery);
    setQueries((prev) => [...prev, ...next20queries]);
  }
  function readQueryInContext(query, index) {
    let out = [...queries];
    out.splice(index, 1, { ...query, read: true });
    setQueries(out);
  }
  useEffect(() => {
    if (!currentUser) return;
    if (!queries) {
      fetchInitialQueries();
    }
  }, []);
  let value = {
    queries,
    fetchNextQueries,
    fetchInitialQueries,
    isQueryLoading,
    readQueryInContext,
  };
  return <QueryContext.Provider value={value}>{props.children}</QueryContext.Provider>;
}
