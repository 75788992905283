import { db } from "../../firebase";
import { deleteImage, uploadImage } from "./storage-funcs";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CATEGORIES API
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// GEt one category from firestore
export async function getCategory(id) {
  const documentRef = db.collection("categories").doc(id);
  return documentRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { data: { id: doc.id, ...doc.data() }, success: true };
      } else {
        return { data: "Document does not exist", success: true };
      }
    })
    .catch((err) => {
      return { success: false, data: err };
    });
}
// Get All categories from firestore
export async function getCategories() {
  const collection = await db.collection("categories").get();
  return collection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

//Upload new Category
export async function uploadNewCategory(category) {
  const { imageFile, title, description } = category;
  // console.log(category);
  const collection = db.collection("categories");
  return await uploadImage(imageFile, "categories").then(async (res) => {
    if (!res.success) return { success: false, message: "Error while uploading image" };
    return await collection
      .add({
        title,
        description,
        image: { url: res.url, name: imageFile.name },
        sub: [],
      })
      .then(() => ({
        success: true,
        message: "Category was saved succesfully",
      }))
      .catch((err) => err);
  });
}
export async function editCategory(category) {
  const { imageFile, title, description, id } = category;
  const docRef = db.collection("categories").doc(id);
  if (!imageFile) {
    return await docRef
      .update({
        title,
        description,
      })
      .then(() => ({
        success: true,
        message: "Category was saved succesfully",
      }))
      .catch((err) => ({
        success: false,
        message: err,
      }));
  } else {
    return await uploadImage(imageFile, "categories").then(async (res) => {
      if (!res.success) return { success: false, message: "Error while uploading image" };
      try {
        await docRef.update({
          title,
          description,
          image: { url: res.url, name: imageFile.name },
        });
        return {
          success: true,
          message: "Category was saved succesfully",
        };
      } catch (err) {
        return {
          success: false,
          message: err,
        };
      }
    });
  }
}
export async function deleteCategory(category) {
  const { id, image } = category;
  const [imgName, ext] = image.name.split(".");
  const documentRef = db.collection("categories").doc(id);
  return await deleteImage(imgName, "categories").then(async (res) => {
    if (!res.success)
      return {
        success: false,
        message: res.message,
      };
    return await documentRef
      .delete()
      .then(() => ({
        success: true,
        message: "Category was deleted succesfully",
      }))
      .catch((err) => ({
        success: false,
        message: "Error While deleting category ",
      }));
  });
}
export async function updateCategoryProducts(categoryId, products) {
  const collection = db.collection("categories").doc(categoryId);

  return collection
    .update({
      products,
    })
    .then(() => ({
      success: true,
      message: "Category updated succesfully",
    }))
    .catch({
      success: false,
      mesasge: "Error while updating category",
    });
}
export async function addSubCategory(parentId, subCategories) {
  const collection = db.collection("categories").doc(parentId);

  return await collection
    .update({
      sub: subCategories,
    })
    .then(() => ({
      success: true,
      message: "Subcategory was saved succesfully",
    }))
    .catch((err) => ({
      success: false,
      message: err,
    }));
}
export async function deleteSubcategory(subCategory, category) {
  const docRef = db.doc(`categories/${category.id}`);
  let products = [];

  try {
    await db
      .collection(`categories/${category.id}/products`)
      .where("subCategory", "==", subCategory)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });
        });
      });

    const res = await deleteProducts(products, category.id);
    return docRef
      .update({
        sub: category.sub.filter((item) => item !== subCategory),
      })
      .then(() => {
        return {
          success: true,
          message: `Sub category ${subCategory} and ${products.length} product${products.length > 1 && "s"
            } successfully deleted`,
        };
      });
  } catch (err) {
    console.log(err);
    return { success: false, mesasge: "Error while deleting subcategory" };
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////PRODUCTS API
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Get single product
export async function getProduct(id) {
  const doc = await db.doc(`products/${id}`).get();
  return { id, ...doc.data() };
}

export async function getAllProdcuts(categoryId) {
  const collectionRef = await db.collection(`categories/${categoryId}/products`).get();
  return collectionRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

//Add product to firebase
export async function addProductToFb(product) {
  const { imageFile, title, stockId, description, subCategory, parentCategory } = product;

  const productCollectionRef = db.collection(`categories/${parentCategory.id}/products`);

  //uplaod image and then upload product
  return uploadImage(imageFile, "products").then(async (res) => {
    if (res.success) {
      try {
        const ref = await productCollectionRef.add({
          title,
          description,
          stockId,
          subCategory,
          image: { url: res.url, name: imageFile.name },
        });
        return { success: true, message: "Product was succesfully created" };
      } catch (err) {
        console.log(err);
        return {
          success: false,
          message: "Error while creating product: " + err,
        };
      }
    } else {
      return {
        success: false,
        message: "Error while creating product: ",
      };
    }
  });
}

//Edit product
export async function editProduct(newProduct) {
  const { imageFile, title, description, stockId, subCategory, parentCategory, id } = newProduct;
  const docRef = db.collection(`categories/${parentCategory.id}/products`).doc(id);
  if (!imageFile) {
    return await docRef
      .update({
        title,
        description,
        stockId,
        subCategory,
      })
      .then(() => ({
        success: true,
        message: "Product was saved succesfully",
      }))
      .catch((err) => {
        console.log(err);
        return {
          success: false,
          message: err,
        };
      });
  } else {
    return await uploadImage(imageFile, "products").then(async (image) => {
      try {
        await docRef.update({
          title,
          description,
          stockId,
          subCategory,
          image: { url: image.url, name: imageFile.name },
        });
        return {
          success: true,
          message: "Product was saved succesfully",
        };
      } catch (err) {
        console.log(err);
        return {
          success: false,
          message: err,
        };
      }
    });
  }
}

//Delete product
export async function deleteProduct(product, categoryId) {
  const { id, image } = product;
  const [imgName, ext] = image.name.split(".");
  const productRef = db.doc(`categories/${categoryId}/products/${product.id}`);
  if (imgName) {
    return await deleteImage(imgName, "products").then(async (res) => {
      if (!res.success)
        return {
          success: false,
          message: "Error While deleting product ",
        };
      return await productRef
        .delete()
        .then(() => {
          return {
            success: true,
            message: "Product was deleted succesfully",
          };
        })
        .catch((err) => ({
          success: false,
          message: "Error While deleting product ",
        }));
    });
  } else {
    return await productRef
      .delete()
      .then(async () => {
        return {
          success: true,
          message: "Product was deleted succesfully",
        };
      })
      .catch((err) => ({
        success: false,
        message: "Error While deleting product ",
      }));
  }
}

export async function deleteProducts(productsToBeDeleted, categoryId) {
  async function delProd(product) {
    try {
      const res = await deleteProduct(product, categoryId);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  try {
    const res = await Promise.all(productsToBeDeleted.map(delProd));
    return {
      success: true,
      message: `Deleted ${productsToBeDeleted.length} product${productsToBeDeleted.length > 1 && "s"
        } succesfully`,
    };
  } catch (err) {
    console.log(err);
    return {
      success: true,
      message: "Error while deleting the product." + err,
    };
  }
}
export async function fetchQueries(limit) {
  const queriesSnap = await db
    .collection("queries")
    .orderBy("createdAt", "desc")
    .limit(limit)
    .get();

  let out = [];
  queriesSnap.forEach((doc) => {
    out.push({ id: doc.id, ...doc.data() });
  });

  return out;
}
export async function fetchQueriesAfter(limit, lastQuery) {
  const queriesSnap = await db
    .collection("queries")
    .orderBy("createdAt", "desc")
    .startAfter(lastQuery.createdAt)
    .limit(limit)
    .get();

  let out = [];
  console.log(queriesSnap.size);
  queriesSnap.forEach((doc) => {
    out.push({ id: doc.id, ...doc.data() });
  });

  return out;
}
export async function saveReadQuery(id) {
  try {
    const queriesSnap = await db.collection("queries").doc(id).update({ read: true });
    return { success: true, message: "Saved successfully" };
  } catch (err) {
    console.log(err);
    return { success: false, message: "Error occured while reading query" };
  }
}
