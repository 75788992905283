import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";

export default function LoadBackdrop({ open }) {
  return (
    <Backdrop open={open} style={{ zIndex: 1001 }}>
      <CircularProgress />
    </Backdrop>
  );
}
