import React, { useState } from "react";
import { Box, Button, CircularProgress, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import data from "./data";
import { useQueryContext } from "src/contexts/queries-context";
import PageTitle from "src/components/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

const CustomerListView = () => {
  const classes = useStyles();
  const { queries, isQueryLoading } = useQueryContext();

  return (
    <Page className={classes.root} title="Queries">
      <PageTitle title="Enqueries" />
      <Container maxWidth={false}>
        {isQueryLoading ? (
          <Grid container justify="center" alignItems="center" className="h-100">
            <CircularProgress />
          </Grid>
        ) : (
          <Box mt={3}>{<Results queries={queries} />}</Box>
        )}
      </Container>
    </Page>
  );
};

export default CustomerListView;
