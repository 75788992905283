import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";

export default function EditableTextField(props) {
  const {
    type,
    keyTitle,
    value,
    handleChange,
    editMode,
    name,
    errors,
    touched,
    handleBlur,
    textFieldProps,
  } = props;
  return (
    <Grid container item xs={12} className="my-2" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">{keyTitle} </Typography>
      </Grid>
      <Grid item xs={12} sm={8} className="my-2">
        <TextField
          {...textFieldProps}
          placeholder="Please set a value"
          disabled={!editMode}
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type || "text"}
          className="w-100"
          fullWidth
          variant={"outlined"}
          value={value}
          name={name}
        />
      </Grid>
    </Grid>
  );
}
