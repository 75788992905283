import { Card, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";

export default function PageTitle({ title = "Page Title" }) {
  return (
    <Grid container className="p-2 bg-white">
      <Grid container item xs={12} justify="center">
        <Typography
          variant="h3"
          style={{ letterSpacing: ".50rem", fontWeight: "bold" }}
          color="primary"
        >
          {" "}
          {title.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );
}
