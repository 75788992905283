import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import * as Yup from "yup";
import { Formik } from "formik";
import { addProductToFb, editProduct } from "src/utils/firebase-utils/firestore-funcs";
import PromtDialog from "src/utils/components/PromtDialog";
import imageCompression from "browser-image-compression";

export default function AddProductDialog(props) {
  const { open, closeDialog, refresh, parentCategory, editMode, product } = props;
  const [image, setImage] = useState(editMode && product ? product.image : "");
  const [promt, setPromt] = useState(false);
  const [promtInfo, setPromtInfo] = useState("");

  const [isLoading, setLoading] = useState(false);

  //Image upload Click
  async function handleUploadClick(e, files) {
    e.preventDefault();
    // setImage({
    //   file: e.target.files[0],
    //   url: URL.createObjectURL(e.target.files[0]),
    // });
    const imageFile = e.target.files[0];
    const originalSize = (imageFile.size / 1024 / 1024).toFixed(2);
    console.log(`originalFile size ${originalSize} MB`);
    if (originalSize <= 0.3) {
      console.log("No need to compress Image");
      return setImage({
        file: imageFile,
        url: URL.createObjectURL(imageFile),
      });
    }

    const options = {
      maxSizeMB: originalSize < 0.5 ? originalSize / 2 : originalSize / 5,
      maxWidthOrHeight: 640,
      useWebWorker: true,
      fileType: ".png",
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedSize = (compressedFile.size / 1024 / 1024).toFixed(2);
      console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedSize} MB`); // smaller than maxSizeMB

      setImage({
        file: compressedFile,
        url: URL.createObjectURL(compressedFile),
      });
    } catch (error) {
      console.log(error);
    }
  }

  function removeImage() {
    setImage({});
  }

  function handlePromtClose() {
    setPromt(false);
  }
  const classes = useStyles();
  return (
    <div>
      <PromtDialog open={promt} dialogInfo={promtInfo} handleClose={handlePromtClose} />
      <Dialog open={open} onClose={closeDialog} maxWidth={"sm"} fullWidth>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              title: editMode && product ? product.title : "",
              stockId: editMode && product ? product.stockId : "",
              subCat: editMode && product ? product.subCategory : "",
              description: editMode && product ? product.description : "",
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(100).required("Title is required"),
              description: Yup.string().max(255).required("Description is required"),
              stockId: Yup.string().required("Stock Id is required"),
              subCat: Yup.string().required("Please select one option"),
            })}
            onSubmit={(values, actions) => {
              if (!image || !image.url) {
                actions.setSubmitting(false);
                return actions.setFieldError("description", "Please upload product image ");
              }
              const newProduct = {
                imageFile: image.file,
                title: values.title,
                stockId: values.stockId,
                description: values.description,
                subCategory: values.subCat,
                parentCategory,
                id: editMode && product.id,
              };

              if (editMode && image.url) {
                return editProduct(newProduct)
                  .then((res) => {
                    actions.setSubmitting(false);
                    if (!res.success) {
                      setPromtInfo({
                        title: "Error",
                        message: `Error while editing product, please try again later.`,
                        btn1: "",
                        btn2: "Ok",
                        btn2Click: () => {
                          handlePromtClose();
                        },
                        error: true,
                      });
                      return setPromt(true);
                    }
                    //If no error whie saving
                    setPromtInfo({
                      title: "Success",
                      message: res.message,
                      btn1: "",
                      btn2: "Ok",
                      btn2Click: () => {
                        refresh();
                        handlePromtClose();
                        closeDialog();
                      },
                      success: true,
                    });
                    return setPromt(true);
                  })
                  .catch((err) => {
                    actions.setSubmitting(false);
                    console.log(err);
                  });
              }

              addProductToFb(newProduct)
                .then((res) => {
                  actions.setSubmitting(false);
                  if (!res.success) {
                    setPromtInfo({
                      title: "Error",
                      message: `Error while creating new category`,
                      btn1: "",
                      btn2: "Ok",
                      btn2Click: () => {
                        handlePromtClose();
                      },
                      error: true,
                    });
                    return setPromt(true);
                  }
                  //If no error whie aving
                  setPromtInfo({
                    title: "Success",
                    message: res.message,
                    btn1: "",
                    btn2: "Ok",
                    btn2Click: () => {
                      refresh();
                      handlePromtClose();
                      closeDialog();
                    },
                    success: true,
                  });
                  return setPromt(true);
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  console.log(err);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => {
              function checkChanges() {
                if (
                  image.url === product.image.url &&
                  values.title === product.title &&
                  values.description === product.description &&
                  values.stockId === product.stockId &&
                  values.subCat === product.subCategory
                )
                  return true;
                return false;
              }
              const disableButton = editMode && checkChanges();
              return (
                <form onSubmit={handleSubmit}>
                  {isSubmitting || isLoading ? (
                    <Backdrop open={isSubmitting || isLoading} style={{ zIndex: 1001 }}>
                      <CircularProgress />
                    </Backdrop>
                  ) : null}
                  <div className="d-flex flex-column-reverse flex-md-row justify-content-between mb-3">
                    <div className={`${classes.col1}`}>
                      <div className="my-2">
                        <TextField
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                          margin="normal"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          name="title"
                          value={values.title}
                          variant="outlined"
                          label="Product Title"
                          size="small"
                        />
                      </div>
                      <div className="my-2">
                        <TextField
                          error={Boolean(touched.stockId && errors.stockId)}
                          helperText={touched.stockId && errors.stockId}
                          fullWidth
                          name="stockId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.stockId}
                          type="text"
                          variant="outlined"
                          label="Stock ID"
                          size="small"
                        />
                      </div>
                      <div className="my-3">
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="subCat-select">Select Subcategory</InputLabel>
                          <Select
                            label="Select Subcategory"
                            type="select"
                            inputProps={{
                              name: "subCat",
                              id: "subCat-select",
                            }}
                            value={values.subCat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.subCat && errors.subCat)}
                          >
                            <MenuItem value={"None"}>None</MenuItem>
                            {parentCategory.sub.map((item) => {
                              return (
                                <MenuItem key={item} value={item} className="text-capitalize">
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {touched.subCat && (
                            <FormHelperText color="secondary">{errors.subCat}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>

                    {/*  Product Image */}
                    <div
                      className={`${classes.col2} p-3 d-flex flex-column align-item-center justify-content-center`}
                    >
                      <div className="p-2 h-100 position-relative border d-flex align-items-center justify-content-center">
                        {image.url ? (
                          <img
                            id="target"
                            src={image.url}
                            className="img-fluid"
                            style={{
                              minHeight: "140px",
                              objectFit: "contain",
                            }}
                          />
                        ) : null}
                        {image.url && (
                          <Button
                            onClick={removeImage}
                            disableFocusRipple
                            style={{ position: "absolute", bottom: 0 }}
                            className="text-danger"
                          >
                            Remove
                          </Button>
                        )}
                        {!image.url ? (
                          <div
                            style={{ minHeight: "140px" }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <input
                              accept="image/*"
                              className={`d-none`}
                              id="contained-button-file"
                              multiple
                              name="image"
                              type="file"
                              onChange={handleUploadClick}
                            />
                            <label htmlFor="contained-button-file">
                              <Fab component="span" className={classes.button}>
                                <AddPhotoAlternateIcon />
                              </Fab>
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* Description */}
                  <div className="my-2">
                    <TextField
                      multiline
                      value={values.description}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      rows={5}
                      fullWidth
                      variant="outlined"
                      label="Description"
                    />
                  </div>

                  {/* Submit Button */}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disableButton}
                  >
                    Save Product
                  </Button>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  col1: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  col2: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
