import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close, Create, TheatersRounded } from "@material-ui/icons";
import AddProductDialog from "./AddProductDialog";
import { deleteProduct, deleteProducts } from "src/utils/firebase-utils/firestore-funcs";
import PromtDialog from "src/utils/components/PromtDialog";
import LoadBackdrop from "src/utils/components/LoadBackdrop";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Product title",
  },
  { id: "stockId", numeric: true, disablePadding: false, label: "Stock ID" },
  {
    id: "subCategory",
    numeric: true,
    disablePadding: false,
    label: "Sub Category",
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    filterList,
    deleteClick,
    clearFilter,
    isFilter,
    selectedItems,
    editClick,
  } = props;
  function filterClick(e) {
    e.preventDefault();
    filterList();
  }

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            All products
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title={selectedItems.length === 1 ? `Edit / Delete` : `Delete`}>
            <>
              {selectedItems.length === 1 && (
                <IconButton aria-label="delete" onClick={editClick}>
                  <Create />
                </IconButton>
              )}
              <IconButton aria-label="delete" onClick={deleteClick}>
                <DeleteIcon />
              </IconButton>
            </>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            {isFilter ? (
              <Button onClick={clearFilter}>
                <Typography variant="caption" color="textSecondary">
                  Clear Filter
                </Typography>
                <Close />
              </Button>
            ) : (
              <Button onClick={filterClick}>
                <Typography variant="caption" color="textSecondary">
                  Filter
                </Typography>
                <FilterListIcon />
              </Button>
            )}
          </Tooltip>
        )}
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
function FitlerDialog(props) {
  const { handleClose, open, subCats, applyFilter } = props;
  const [value, setValue] = React.useState("");
  function handleChange(e) {
    setValue(e.target.value);
  }
  function handleFilterClick() {
    applyFilter(value);
  }
  return (
    <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={"sm"}>
      <DialogTitle>Select filter option</DialogTitle>
      <DialogContent dividers>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="filter-select">Subcategory</InputLabel>
          <Select
            labelId="filter-select"
            id="filter-select"
            value={value}
            className="text-capitalize"
            onChange={handleChange}
            label="Select Filter"
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            {subCats.map((item) => {
              return (
                <MenuItem value={item} key={item} className="text-capitalize">
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleFilterClick}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ProductTable(props) {
  const classes = useStyles();
  const { products, category, refresh } = props;
  const [items, setItems] = React.useState(products);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("title");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterDialog, setFilterDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);
  const [promt, setPromt] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //Filter options
  const filterListClick = () => {
    setFilterDialog(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const clearFilter = () => {
    setItems(products);
  };
  const applyFilter = (value) => {
    if (value.toLowerCase() === "none") {
      clearFilter();
      setFilterDialog(false);
    } else {
      setItems(products.filter((item) => item.subCategory === value));
      setFilterDialog(false);
    }
  };

  //Edit / Delete click
  const editItemClick = () => {
    setSelected(products.filter((item) => item.id === selected[0]));
    setEditDialog(true);
  };
  const closeEditDialog = () => {
    setSelected([selected[0].id]);
    setEditDialog(false);
  };

  const confirmDelete = async () => {
    setLoading(true);
    setPromt(false);

    const toBeDeleted = [];
    selected.map((id) => {
      return products.map((item) => {
        if (item.id === id) return toBeDeleted.push(item);
      });
    });

    try {
      const res = await deleteProducts(toBeDeleted, category.id);
      return setPromt({
        open: true,
        info: {
          title: "Success",
          message: `${toBeDeleted.length} products were successfully deleted`,
          btn1: "",
          btn2: "OK",
          btn2Click: () => {
            refresh();
            closePromt();
          },
          success: true,
        },
      });
    } catch (err) {
      console.log(err);
      setPromt({
        open: true,
        info: {
          info: {
            title: "Error",
            message: `Error while deleting products, please try again after sometime`,
            btn1: "",
            btn2: "Try again",
            btn2Click: () => {
              closePromt();
            },
            warning: true,
          },
        },
      });
    }
  };

  function closePromt() {
    setPromt(false);
  }

  //Ask to confirm delete
  function handleDeleteClick() {
    setPromt({
      open: true,
      info: {
        title: "Warning",
        message: `Are you sure about deleting these ${selected.length} products ?`,
        btn1: "Cancel",
        btn2: "Delete",
        btn2Click: () => {
          confirmDelete();
        },
        warning: true,
      },
    });
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <div className={classes.root}>
      <FitlerDialog
        open={filterDialog}
        handleClose={setFilterDialog}
        subCats={category.sub}
        applyFilter={applyFilter}
        isFilter={items.length !== products.length}
        clearFilter={clearFilter}
      />
      {editDialog && (
        <AddProductDialog
          open={editDialog}
          closeDialog={closeEditDialog}
          product={selected[0]}
          parentCategory={category}
          editMode
          refresh={refresh}
        />
      )}
      {isLoading && <LoadBackdrop open={isLoading} />}
      {promt.open && (
        <PromtDialog handleClose={closePromt} open={promt.open} dialogInfo={promt.info} />
      )}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          filterList={filterListClick}
          clearFilter={clearFilter}
          deleteClick={handleDeleteClick}
          editClick={editItemClick}
          selectedItems={selected}
          isFilter={items.length !== products.length}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.title}
                      </TableCell>
                      <TableCell align="right">{row.stockId}</TableCell>
                      <TableCell align="right">{row.subCategory}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
