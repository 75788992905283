import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Create, Delete } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import LoadBackdrop from "src/utils/components/LoadBackdrop";
import { saveAboutCompany, deleteSection } from "src/utils/firebase-utils/company-firestore";
import * as Yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PromtDialog from "src/utils/components/PromtDialog";

export default function AboutCompany(props) {
  const { data, reRender } = props;
  const [dialog, setDialog] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [propmt, setPrompt] = React.useState(false);
  function openAddSection() {
    setDialog(true);
  }
  function closeAddSection() {
    setDialog(false);
    setEdit(false);
  }
  function editSection(index) {
    setDialog(true);
    setEdit({ section: data[index], index });
  }
  function handleDeleteSection(index) {
    const payload = [...data];

    setPrompt({
      open: true,
      title: "Warning",
      message: "Are you sure about deleting this section",
      btn1: "",
      btn1: "Cancel",
      btn2: "Delete",
      btn2Click: () => confirmDelete(),
      warning: true,
    });
    function confirmDelete() {
      payload.splice(index, 1);
      saveAboutCompany(payload).then((res) => {
        if (res.success) {
          setPrompt(false);
          reRender();
        }
      });
    }
  }
  return (
    <Grid container justify="center" alignItems="center">
      <PromtDialog
        open={propmt && propmt.open}
        dialogInfo={propmt}
        handleClose={() => setPrompt(false)}
      />
      <AboutSectionDialog
        open={dialog}
        closeDialog={closeAddSection}
        data={data}
        section={edit && edit.section}
        editIndex={edit && edit.index}
        reRender={reRender}
      />
      <Fab variant="extended" color="primary" onClick={openAddSection} className="my-2">
        <Add className="mr-2" />
        Add Section
      </Fab>
      {data &&
        data?.map((section, index) => {
          return (
            <Grid item xs={12} key={index + section.title}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems="center" justify="space-between">
                    <Typography variant="h4">{section.title}</Typography>
                    <Grid item>
                      <IconButton onClick={() => editSection(index)}>
                        <Create />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteSection(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{section.content}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
    </Grid>
  );
}

function AboutSectionDialog(props) {
  const { section, reRender, editIndex, open, closeDialog, data = [] } = props;

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogTitle>{editIndex >= 0 ? "Edit" : "Add"} section</DialogTitle>

      <Formik
        initialValues={{
          title: section && section.title ? section.title : "",
          content: section && section.content ? section.content : "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(40).required("Title is required"),
          content: Yup.string().min(10).required("Content is required"),
        })}
        onSubmit={(values, actions) => {
          let payload;

          if (editIndex === 0 || editIndex > 0) {
            //If edit mode is enabled
            payload = [...data];
            payload.splice(editIndex, 1, { title: values.title, content: values.content });
          } else {
            data.push({ title: values.title, content: values.content });
            payload = [...data];
            console.log("no Edit", editIndex);
          }
          saveAboutCompany(payload).then((res) => {
            if (res.success) {
              closeDialog();
              reRender();
            } else {
              actions.setSubmitting(false);
              console.log(res.message);
            }
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          function checkChanges() {
            if (
              values.title === (section && section.title) &&
              values.content === (section && section.content)
            )
              return true;
            return false;
          }
          return (
            <form onSubmit={handleSubmit}>
              <LoadBackdrop open={isSubmitting} />
              <DialogContent>
                <Grid container alignItems="stretch">
                  <Grid item xs={12}>
                    <div className="my-2">
                      <TextField
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        fullWidth
                        variant="outlined"
                        label="Section Title"
                      />
                    </div>
                    <div className="my-2">
                      <TextField
                        value={values.content}
                        name="content"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.content && errors.content)}
                        helperText={touched.content && errors.content}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        label="Section Content"
                      />
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || checkChanges()}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
