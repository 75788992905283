import { db } from "../../firebase";
import { deleteImage, uploadImage } from "./storage-funcs";

export async function getCompanyData() {
  const documentRef = db.collection("company");
  try {
    const documentsnap = await documentRef.get();
    let out = {};
    documentsnap.forEach((doc) => {
      out[doc.id] = doc.data();
    });
    return { success: true, data: out };
  } catch (err) {
    console.log("Error while fetching data from firebase", err);
    return { success: false, data: err };
  }
}

export async function saveTitleLogo(data) {
  const docRef = db.collection("company").doc("head");
  let doc = {};
  if (data?.logo)
    await uploadImage(data.logo, "company").then((res) => {
      if (!res.success) return { success: false, message: "Error while uploading image" };
      doc["logo"] = { url: res.url, name: data?.logo?.name };
    });
  doc.title = data.title;
  doc.tagline = data.tagline;
  return docRef
    .set(doc, { merge: true })
    .then(() => ({
      success: true,
      message: "Title and logo saved succesfully",
    }))
    .catch((err) => ({
      success: false,
      message: "Error while saving Title or Logo ",
    }));
}
export async function saveAboutCompany(data) {
  const docRef = db.collection("company").doc("about");
  return docRef
    .set({ data: [...data] }, { merge: true })
    .then((res) => {
      return { success: true, message: "Firestore operation succesful" };
    })
    .catch((err) => {
      return { success: false, message: "Firestore operation unsucessful" };
    });
}
export async function saveContactDetails(data) {
  const docRef = db.collection("company").doc("contact");
  return docRef
    .set({ ...data }, { merge: true })
    .then(() => {
      return { success: true, message: "Firestore operation succesful" };
    })
    .catch((err) => {
      console.log(err);
      return { success: false, message: "Firestore operation unsucessful" };
    });
}
export async function saveAddress(data) {
  const docRef = db.collection("company").doc("contact");
  return docRef
    .set({ ...data }, { merge: true })
    .then(() => {
      return { success: true, message: "Firestore operation succesful" };
    })
    .catch((err) => {
      console.log(err);
      return { success: false, message: "Firestore operation unsucessful" };
    });
}
export async function saveFeature(data) {
  const docRef = db.collection("company").doc("features");
  return docRef
    .set({ data: [...data] }, { merge: true })
    .then((res) => {
      return { success: true, message: "Firestore operation succesful" };
    })
    .catch((err) => {
      return { success: false, message: "Firestore operation unsucessful" };
    });
}
export async function saveAboutImage(data) {
  const docRef = db.collection("company").doc("about");
  let doc = { ...data };
  let { images } = data;
  await Promise.all(
    images.map(async (section, index) => {
      let out = { ...section };
      if (section.image.file) {
        await uploadImage(section.image.file, "company/about")
          .then((res) => {
            if (!res.success) return { success: false, message: "Error while uploading image" };
            out["image"] = { url: res.url, name: section.image.file.name };
          })
          .catch((err) => {
            console.log(err);
            return { success: false, message: "Error while uploading image" };
          });
        doc.images.splice(index, 1, out);
      }
    })
  );
  return docRef
    .set({ ...doc }, { merge: true })
    .then((res) => {
      return { success: true, message: "Firestore operation succesful" };
    })
    .catch((err) => {
      return { success: false, message: "Firestore operation unsucessful" };
    });
}
