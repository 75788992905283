import { Button, CardContent, Grid, IconButton, Card, Divider } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import { Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { useState } from "react";
import EditableTextField from "src/utils/components/EditableTextField";
import { DEFAULT_ERROR } from "src/utils/constant";
import { saveContactDetails } from "src/utils/firebase-utils/company-firestore";
import * as Yup from "yup";

function ContactInfo(props) {
  const { data, enqueueSnackbar } = props;
  const [editMode, setEditMode] = React.useState(false);
  function enableEdit() {
    setEditMode(true);
  }
  function cancelEdit() {
    setEditMode(false);
  }

  return (
    <Card>
      <CardContent>
        <Formik
          initialValues={{
            primaryNo: data && data.primaryNo ? data.primaryNo : "",
            secondaryNo: data && data.secondaryNo ? data.secondaryNo : "",
            primaryEmail: (data && data.primaryEmail) || "",
            secondaryEmail: (data && data.secondaryEmail) || "",
            facebookLink: data && data.facebookLink ? data.facebookLink : "",
            linkedinLink: data && data.linkedinLink ? data.linkedinLink : "",
          }}
          validationSchema={Yup.object().shape({
            primaryNo: Yup.string()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(10, "Must be exactly 10 digits")
              .max(10, "Must be exactly 10 digits")
              .required("Please enter phone number"),
            secondaryNo: Yup.string()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(10, "Must be exactly 5 digits")
              .max(10, "Must be exactly 5 digits")
              .required("Please enter phone number"),
            primaryEmail: Yup.string().email("Invalid email").required("Please enter an email"),
            secondaryEmail: Yup.string().email("Invalid email").required("Please enter an email"),
            facebookLink: Yup.string().required("This field is requried"),
            linkedinLink: Yup.string().required("This field is requried"),
          })}
          onSubmit={async (values, actions) => {
            saveContactDetails(values).then((res) => {
              if (!res.success) return enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
              setEditMode(false);
              enqueueSnackbar("Changes Saved", { variant: "success" });
            });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
            function checkChanges() {
              return false;
            }
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid container justify="flex-end" item xs={12} spacing={3}>
                    {!editMode ? (
                      <IconButton onClick={enableEdit}>
                        <Create />
                      </IconButton>
                    ) : (
                      <>
                        <Grid item>
                          <Button className="text-danger" onClick={cancelEdit}>
                            Cancel
                          </Button>{" "}
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={checkChanges()}
                          >
                            Save
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Primary Email (This will be used for notifications and queries)"
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      type="email"
                      handleChange={handleChange}
                      name={"primaryEmail"}
                      value={values.primaryEmail}
                      editMode={editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Secondary Email"
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      type="email"
                      handleChange={handleChange}
                      name={"secondaryEmail"}
                      value={values.secondaryEmail}
                      editMode={editMode}
                    />
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Primary Contact No."
                      handleBlur={handleBlur}
                      errors={errors}
                      type="number"
                      touched={touched}
                      handleChange={handleChange}
                      name={"primaryNo"}
                      value={values.primaryNo}
                      editMode={editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      editMode={editMode}
                      keyTitle="Secondary Contact No."
                      value={values.secondaryNo}
                      handleBlur={handleBlur}
                      errors={errors}
                      type="number"
                      touched={touched}
                      handleChange={handleChange}
                      name={"secondaryNo"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Facebook "
                      value={values.facebookLink}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      editMode={editMode}
                      handleChange={handleChange}
                      name={"facebookLink"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Linkedin"
                      value={values.linkedinLink}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      editMode={editMode}
                      name={"linkedinLink"}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
}
export default withSnackbar(ContactInfo);
