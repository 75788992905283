import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import routes from "src/routes";
import { AuthProvider } from "./contexts/auth-context";
import { SnackbarProvider } from "notistack";
import QueryProvider from "./contexts/queries-context";

const App = () => {
  const routing = useRoutes(routes);

  return (
    <AuthProvider>
      <QueryProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SnackbarProvider maxSnack={3}>{routing}</SnackbarProvider>
        </ThemeProvider>
      </QueryProvider>
    </AuthProvider>
  );
};

export default App;
