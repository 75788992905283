import { Button, Card, CardContent, Grid, IconButton } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import { Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { useState } from "react";
import EditableTextField from "src/utils/components/EditableTextField";
import { DEFAULT_ERROR } from "src/utils/constant";
import * as Yup from "yup";
import { saveAddress } from "src/utils/firebase-utils/company-firestore";

function CompanyAddress(props) {
  const { data, enqueueSnackbar } = props;
  const [editMode, setEditMode] = useState(false);
  function enableEdit() {
    setEditMode(true);
  }
  function cancelEdit() {
    setEditMode(false);
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Formik
          initialValues={{
            primaryAddress: data && data.primaryAddress ? data.primaryAddress : "",
            secondaryAddress: data && data.secondaryAddress ? data.secondaryAddress : "",
          }}
          validationSchema={Yup.object().shape({
            primaryAddress: Yup.string().required("This field is requried"),
            secondaryAddress: Yup.string().required("This field is requried"),
          })}
          onSubmit={async (values, actions) => {
            saveAddress(values).then((res) => {
              if (!res.success) return enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
              setEditMode(false);
              enqueueSnackbar("Changes Saved", { variant: "success" });
            });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
            function checkChanges() {
              return false;
            }
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid container justify="flex-end" item xs={12} spacing={3}>
                    {!editMode ? (
                      <IconButton onClick={enableEdit}>
                        <Create />
                      </IconButton>
                    ) : (
                      <>
                        <Grid item>
                          <Button className="text-danger" onClick={cancelEdit}>
                            Cancel
                          </Button>{" "}
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={checkChanges()}
                          >
                            Save
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Primary Address"
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      name={"primaryAddress"}
                      value={values.primaryAddress}
                      editMode={editMode}
                      textFieldProps={{
                        multiline: true,
                        rowsMax: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditableTextField
                      keyTitle="Secondary Address"
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      name={"secondaryAddress"}
                      value={values.secondaryAddress}
                      editMode={editMode}
                      textFieldProps={{
                        multiline: true,
                        rowsMax: 5,
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
}
export default withSnackbar(CompanyAddress);
