import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { SentimentDissatisfied } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import { getCategory, getAllProdcuts } from "src/utils/firebase-utils/firestore-funcs";
import ProductTable from "./ProductsTable";
import Title from "./Title";
import Toolbar from "./Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductsView = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [category, setCategory] = useState(false);
  const [products, setProducts] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [filterProducts, setFiltered] = useState([]);

  async function loadCategory() {
    const res = await getCategory(id);
    return res.data;
  }
  async function fetchProducts(categoryId) {
    const res = await getAllProdcuts(categoryId);
    return res;
  }
  function handleSearch(e) {
    let input = e.target.value;

    if (input === "") return setFiltered(products);
    const filtered = products.filter((products) => {
      return (
        products.title.toLowerCase().includes(input.toLowerCase()) ||
        products.stockId.toLowerCase().includes(input.toLowerCase())
      );
    });

    setFiltered(filtered);
  }
  async function loadData() {
    setLoading(true);
    try {
      const categoryRes = await loadCategory();
      setCategory(categoryRes);
      const products = await fetchProducts(categoryRes.id);
      setProducts(products);
      setFiltered(products);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (!category) {
      loadData();
    }
  }, []);
  return (
    <Page className={classes.root} title="Products">
      <PageTitle title={"Products"} />
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && category && category.title && (
        <Grid>
          <Title category={category} refresh={loadData} />

          {products.length > 0 ? (
            <>
              <Toolbar refresh={loadData} category={category} handleSearch={handleSearch} />
              <ProductTable products={filterProducts} category={category} refresh={loadData} />
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              mt={10}
              alignItems="center"
              justifyContent="center"
            >
              <SentimentDissatisfied fontSize="large" />
              <Typography variant="h4" color="textSecondary" align="center" className="my-4">
                No Data to show here, Please find the button to add products in bottom right of the
                screen
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Page>
  );
};
export default ProductsView;
