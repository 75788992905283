import { Button, Chip, Fab, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SubCategoryDialog from "./SubCategoryDialog";
import AddIcon from "@material-ui/icons/Add";
import AddProductDialog from "./AddProductDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    position: "fixed",
    bottom: 30,
    right: 30,
    zIndex: 1001,
  },
}));
export default function Title({ category, refresh }) {
  const [openDialog, setDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);

  function openAddDialog() {
    setAddDialog(true);
  }
  function closeAddDialog() {
    setAddDialog(false);
  }

  function openDialogBox() {
    setDialog(true);
  }
  function closeDialog() {
    setDialog(false);
  }
  const classes = useStyles();
  return (
    <div>
      {openDialog && (
        <SubCategoryDialog
          open={openDialog}
          closeDialog={closeDialog}
          category={category}
          refresh={refresh}
        />
      )}
      {addDialog && (
        <AddProductDialog
          parentCategory={category}
          open={openAddDialog}
          closeDialog={closeAddDialog}
          refresh={refresh}
        />
      )}
      <div className="d-flex flex-column flex-sm-row justify-content-between border-top p-3 bg-white">
        <div className="">
          <Fab
            className={`my-2 my-sm-0 ${classes.addButton}`}
            aria-label={"add-product"}
            onClick={openAddDialog}
            variant="extended"
            size="large"
            color="primary"
          >
            <AddIcon />
            Add product
          </Fab>
          <Typography variant="h2" color="textSecondary">
            {category.title}
          </Typography>
        </div>
        <div className="my-2 my-sm-0">
          <Button color="primary" variant="contained" onClick={openDialogBox} fullWidth>
            Edit Subcategories
          </Button>
        </div>
      </div>
      {category.sub.length > 0 ? (
        <div
          className="d-flex flex-nowrap align-items-center bg-white border-top"
          style={{ overflowX: "scroll", maxWidth: "100vw" }}
        >
          {category.sub.map((item, index) => {
            return (
              <div className="m-2 text-capitalize" key={item + index}>
                <Chip label={item} variant="outlined" />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
