import {
  Badge,
  Box,
  Card,
  Checkbox,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { saveReadQuery } from "src/utils/firebase-utils/firestore-funcs";
import { DEFAULT_ERROR } from "src/utils/constant";
import { useQueryContext } from "src/contexts/queries-context";
import QueryDialog from "../QueryDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, queries, ...rest }) => {
  const classes = useStyles();
  const [selectedQueryIds, setSelectedQueryIds] = useState([]);
  const [page, setPage] = useState(0);
  const [queryDialog, setQueryDialog] = useState(null);

  const { readQueryInContext, fetchNextQueries } = useQueryContext();

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = queries.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedQueryIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQueryIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedQueryIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedQueryIds.slice(1));
    } else if (selectedIndex === selectedQueryIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedQueryIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedQueryIds.slice(0, selectedIndex),
        selectedQueryIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQueryIds(newSelectedCustomerIds);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchNextQueries();
  };
  const openQuery = (query, index) => {
    readQuery(query, index);
    viewQuery(query);
  };
  const viewQuery = (query) => {
    setQueryDialog({ open: true, query });
  };
  const readQuery = async (query, index) => {
    const res = await saveReadQuery(query.id);
    if (!res.success) enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
    readQueryInContext(query, index);
  };
  const closeQueryDialog = () => {
    setQueryDialog(null);
  };

  return (
    <div>
      {queryDialog ? (
        <QueryDialog
          open={Boolean(queryDialog)}
          onClose={closeQueryDialog}
          query={queryDialog.query}
        />
      ) : null}
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedQueryIds.length === queries.length}
                      color="primary"
                      indeterminate={
                        selectedQueryIds.length > 0 && selectedQueryIds.length < queries.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Query date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queries.slice(page * 10, ((page * 10) + 10))
                  .sort((a, b) => moment(b.createdAt.toDate()).unix() - moment(a.createdAt.toDate()).unix())
                  .map((query, index) => (
                    <TableRow
                      hover
                      key={query.id}
                      selected={selectedQueryIds.indexOf(query.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedQueryIds.indexOf(query.id) !== -1}
                          onChange={(event) => handleSelectOne(event, query.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          onClick={() => openQuery(query, index)}
                          variant="body2"
                          style={{ cursor: "pointer" }}
                        >
                          {"Open"}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Badge
                            color="secondary"
                            variant="dot"
                            invisible={query.read}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {query.name}
                            </Typography>
                          </Badge>
                        </Box>
                      </TableCell>
                      <TableCell>{query.email}</TableCell>
                      <TableCell>{query.phone}</TableCell>
                      <TableCell>{moment(query.createdAt.toDate()).format("DD/MM/YYYY")}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={queries.length}
          rowsPerPage={10}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={null}
        />
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  queries: PropTypes.array.isRequired,
};

export default Results;
