import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import React, { useState } from "react";
import Close from "@material-ui/icons/Close";
import {
  deleteCategory,
  editCategory,
  uploadNewCategory,
} from "src/utils/firebase-utils/firestore-funcs";
import * as Yup from "yup";
import PromtDialog from "src/utils/components/PromtDialog";
import imageCompression from "browser-image-compression";

export default function CategoryDialog(props) {
  //This dialog supports edit mode
  const { open, closeDialog, editMode, category, refresh, ...rest } = props;
  const [promt, setPromt] = useState(false);
  const [promtInfo, setPromtInfo] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [image, setImage] = useState({
    url: editMode ? category.image.url : "",
  });

  //Image upload Click
  async function handleUploadClick(e, files) {
    e.preventDefault();
    // setImage({
    //   file: e.target.files[0],
    //   url: URL.createObjectURL(e.target.files[0]),
    // });
    const imageFile = e.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${(imageFile.size / 1024 / 1024).toFixed(2)} MB`);

    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 640,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`); // smaller than maxSizeMB
      setImage({
        file: compressedFile,
        url: URL.createObjectURL(compressedFile),
      });
    } catch (error) {
      console.log(error);
    }
  }

  function removeImage() {
    setImage({});
  }
  function handleDeleteClick() {
    setPromtInfo({
      title: "Confirm delete",
      message: "Are you sure you want to delete this category ?",
      btn1: "Cancel",
      btn2: "Delete",
      btn2Click: handleDeleteCategory,
      warning: true,
    });
    return setPromt(true);
  }
  function handlePromtClose() {
    setPromt(false);
  }

  //Final click to delete category
  function handleDeleteCategory() {
    setPromt(false);
    setLoading(true);
    deleteCategory(category)
      .then((res) => {
        if (!res.success) {
          setPromtInfo({
            title: "Error",
            message: res.message,
            btn1: "",
            btn2: "OK",
            btn2Click: handlePromtClose,
            error: true,
          });
          setLoading(false);
          return handlePromtClose();
        }
        setPromtInfo({
          title: "Succcess",
          message: res.message,
          btn1: "",
          btn2: "OK",
          btn2Click: () => {
            refresh();
            closeDialog();
          },
          success: true,
        });
        setLoading(false);
        return setPromt(true);
      })
      .catch((err) => {
        setLoading(false);
        setPromtInfo({
          title: "Error",
          message: err,
          btn1: "",
          btn2: "OK",
          btn2Click: handlePromtClose,
          error: true,
        });
        setPromt(true);
      });
  }
  const classes = useStyles();
  return (
    <div>
      <PromtDialog open={promt} dialogInfo={promtInfo} handleClose={handlePromtClose} />
      <Dialog
        onClose={() => {
          closeDialog();
        }}
        open={open}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={closeDialog}>
          Add product category
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              title: editMode ? category.title : "",
              description: editMode ? category.description : "",
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(100).required("Title is required"),
              description: Yup.string().min(600).max(1000).required("Description is required"),
            })}
            onSubmit={(values, actions) => {
              //EDIT MODE
              if (editMode && image.url) {
                const editedCategory = {
                  ...category,
                  ...values,
                  imageFile: image.file,
                };
                return editCategory(editedCategory)
                  .then((res) => {
                    actions.setSubmitting(false);
                    if (!res.success) {
                      setPromtInfo({
                        title: "Error",
                        message: `Error while creating new category`,
                        btn1: "",
                        btn2: "Ok",
                        btn2Click: () => {
                          handlePromtClose();
                        },
                        error: true,
                      });
                      return setPromt(true);
                    }
                    //If no error whie aving
                    setPromtInfo({
                      title: "Success",
                      message: res.message,
                      btn1: "",
                      btn2: "Ok",
                      btn2Click: () => {
                        refresh();
                        handlePromtClose();
                        closeDialog();
                      },
                      success: true,
                    });
                    return setPromt(true);
                  })
                  .catch((err) => {
                    actions.setSubmitting(false);
                    console.log(err);
                  });
              } else if (editMode) {
                actions.setSubmitting(false);
                return actions.setFieldError("description", "Please choose a image to upload");
              }
              // For new category
              const newCategory = { ...values, imageFile: image.file };
              if (!newCategory.imageFile) {
                actions.setSubmitting(false);
                return actions.setFieldError("description", "Please choose a image to upload");
              }

              uploadNewCategory(newCategory)
                .then((res) => {
                  if (!res.success) {
                    setPromtInfo({
                      title: "Error",
                      message: `Error while creating new category`,
                      btn1: "",
                      btn2: "Ok",
                      btn2Click: () => {
                        handlePromtClose();
                      },
                      error: true,
                    });
                    return setPromt(true);
                  }
                  setPromtInfo({
                    title: "Success",
                    message: `Category ${values.title} was succesfully created, to add products please open the category then add products`,
                    btn1: "",
                    btn2: "Ok",
                    btn2Click: () => {
                      handlePromtClose();
                      closeDialog();
                    },
                    success: true,
                  });
                  setPromt(true);
                  return refresh();
                })
                .catch((err) => console.log(err));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => {
              function checkChanges() {
                if (
                  image.url === category.image.url &&
                  values.title === category.title &&
                  values.description === category.description
                )
                  return true;
                return false;
              }
              const disableButton = editMode && checkChanges();
              return (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" justifyContent="center">
                    {image.url ? (
                      <img id="target" src={image.url} style={{ width: "25%", maxheight: "50%" }} />
                    ) : null}
                    {!image.url ? (
                      <>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          name="image"
                          type="file"
                          onChange={handleUploadClick}
                        />
                        <label htmlFor="contained-button-file">
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                      </>
                    ) : (
                      <Button onClick={removeImage} disableFocusRipple>
                        <Close />
                      </Button>
                    )}
                  </Box>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="Cateory Title"
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.title}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Description"
                    margin="normal"
                    name="description"
                    multiline
                    rows={4}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="description"
                    value={values.description}
                    variant="outlined"
                  />
                  <Box my={2}>
                    {isSubmitting || isLoading ? (
                      <Backdrop open={isSubmitting || isLoading} style={{ zIndex: 1001 }}>
                        <CircularProgress />
                      </Backdrop>
                    ) : null}
                    <Button
                      color="primary"
                      disabled={editMode ? disableButton : isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                    {editMode && (
                      <Button
                        color="default"
                        style={{ color: "red" }}
                        fullWidth
                        size="large"
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));
