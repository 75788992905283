import { uniqueId } from "lodash";
import { fbStore } from "src/firebase";
const storageRef = fbStore.ref();

export async function uploadImage(imageFile, folder) {
  const imageName = imageFile.name.split(".");
  const imagesRef = storageRef.child(`images/${folder}/${uniqueId(imageName[0])}`);
  return imagesRef
    .put(imageFile)
    .then(async (snapshot) => {
      const downloadURL = await snapshot.ref.getDownloadURL();
      console.log("Image saved");
      return { success: true, url: downloadURL };
    })
    .catch((err) => {
      console.log(err, "image-upload");
      return { success: false, message: err };
    });
}
export async function deleteImage(imageName, folder) {
  const imageRef = storageRef.child(`images/${folder}/${imageName}`);
  return await imageRef
    .delete()
    .then(() => {
      console.log("Image Deleted");
      return { success: true, message: "Image deleted" };
    })
    .catch((err) => {
      console.log(err, "image-delete");
      return { success: false, message: err };
    });
}
