import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Fab,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Create, Delete } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import imageCompression from "browser-image-compression";
import { Formik } from "formik";
import React, { useState } from "react";
import ImageUploader from "src/components/ImageUploader";
import LoadBackdrop from "src/utils/components/LoadBackdrop";
import PromtDialog from "src/utils/components/PromtDialog";
import { saveAboutImage, saveFeature } from "src/utils/firebase-utils/company-firestore";
import * as Yup from "yup";

export default function CompanyImages(props) {
  const { data, reRender } = props;
  const [dialog, setDialog] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [propmt, setPrompt] = React.useState(false);
  function openAddSection() {
    setDialog(true);
  }
  function closeAddSection() {
    setDialog(false);
    setEdit(false);
  }
  function editSection(index) {
    setDialog(true);
    setEdit({ section: data.images[index], index });
  }
  function handleDeleteSection(index) {
    const payload = { ...data };

    setPrompt({
      open: true,
      title: "Warning",
      message: "Are you sure about deleting this section",
      btn1: "",
      btn1: "Cancel",
      btn2: "Delete",
      btn2Click: () => confirmDelete(),
      warning: true,
    });
    function confirmDelete() {
      payload.images.splice(index, 1);
      saveAboutImage(payload).then((res) => {
        if (res.success) {
          setPrompt(false);
          reRender();
        }
      });
    }
  }
  return (
    <Grid container justify="center" alignItems="center">
      <PromtDialog
        open={propmt && propmt.open}
        dialogInfo={propmt}
        handleClose={() => setPrompt(false)}
      />
      {dialog ? (
        <AddImageDialog
          open={dialog}
          closeDialog={closeAddSection}
          data={data}
          section={edit && edit.section}
          editIndex={edit && edit.index}
          reRender={reRender}
        />
      ) : null}
      <Fab variant="extended" color="primary" onClick={openAddSection} className="my-2">
        <Add className="mr-2" />
        Add Image
      </Fab>
      {data &&
        data?.images?.map((imageSection, index) => {
          return (
            <Grid item xs={12} key={index + imageSection.title}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems="center" justify="space-between">
                    <Typography variant="h4">{imageSection.title}</Typography>
                    <Grid item>
                      <IconButton onClick={() => editSection(index)}>
                        <Create />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteSection(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <img
                    src={imageSection.image.url}
                    className={`img-fluid`}
                    style={{ objectFit: "cover" }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
    </Grid>
  );
}

function AddImageDialog(props) {
  const { section = {}, reRender, editIndex, open, closeDialog, data = [] } = props;

  const [image, setImage] = useState(section?.image?.url ? section?.image : {});

  console.log(section.image, ">>>>");
  async function handleUploadClick(e, files) {
    const imageFile = e.target.files[0];
    const originalSize = (imageFile.size / 1024 / 1024).toFixed(2);
    console.log(`originalFile size ${originalSize} MB`);
    if (originalSize <= 0.3) {
      console.log("No need to compress Image");
      return setImage({
        file: imageFile,
        url: URL.createObjectURL(imageFile),
      });
    }

    const options = {
      maxSizeMB: originalSize < 0.5 ? originalSize / 1.5 : originalSize / 1.5,
      maxWidthOrHeight: 640,
      useWebWorker: true,
      fileType: ".png",
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedSize = (compressedFile.size / 1024 / 1024).toFixed(2);
      console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedSize} MB`); // smaller than maxSizeMB

      setImage({
        file: compressedFile,
        url: URL.createObjectURL(compressedFile),
      });
    } catch (error) {
      console.log(error);
    }
  }
  function removeImage() {
    setImage({});
  }
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogTitle>{editIndex >= 0 ? "Edit" : "Add"} Image</DialogTitle>

      <Formik
        initialValues={{
          title: section && section.title ? section.title : "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(40).required("Title is required"),
        })}
        onSubmit={(values, actions) => {
          let payload = { ...data };
          if (!image || !image.url) {
            actions.setSubmitting(false);
            return actions.setFieldError("title", "Please upload a image ");
          }
          if (editIndex === 0 || editIndex > 0) {
            //If edit mode is enabled
            payload.images.splice(editIndex, 1, { title: values.title, image: image });
          } else {
            payload = { ...data };
            if (!payload.images) payload.images = [];
            payload.images.push({ title: values.title, image: image });
          }

          saveAboutImage(payload).then((res) => {
            if (res.success) {
              closeDialog();
              reRender();
            } else {
              console.log(res);
              actions.setSubmitting(false);
              console.log(res.message);
            }
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          function checkChanges() {
            if (values.title === (section && section.title) && section?.image?.url === image.url)
              return true;
            return false;
          }
          return (
            <form onSubmit={handleSubmit}>
              <LoadBackdrop open={isSubmitting} />
              <DialogContent>
                <Grid container alignItems="stretch">
                  <Grid item xs={12}>
                    <div className="my-2">
                      <TextField
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        fullWidth
                        variant="outlined"
                        label="Image Title"
                      />
                    </div>
                    <div className="my-2">
                      <div>
                        <ImageUploader
                          inputProps={{ name: "image" }}
                          handleUpload={handleUploadClick}
                          removeImage={removeImage}
                          image={image}
                          editMode={true}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || checkChanges()}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
