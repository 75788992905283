import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import AccountView from "src/views/account/AccountView";
import CustomerListView from "src/views/queries/QueriesListView";
import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import CategoryListView from "src/views/category/CategoryListView";
import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";
import { useAuth } from "./contexts/auth-context";
import ProductsView from "src/views/category/ProductListView";
import ComapnyPage from "./views/company";

const routes = [
  {
    path: "app",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      { path: "account", element: <AccountView /> },
      { path: "queries", element: <CustomerListView /> },
      { path: "dashboard", element: <DashboardView /> },
      {
        path: "categories",
        element: <CategoryListView />,
      },
      {
        path: "company",
        element: <ComapnyPage />,
      },
      { path: "products/:id", element: <ProductsView /> },
      { path: "settings", element: <SettingsView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: (
          <PrivateRoute invert>
            <LoginView />
          </PrivateRoute>
        ),
      },
      {
        path: "register",
        element: (
          <PrivateRoute invert>
            <RegisterView />{" "}
          </PrivateRoute>
        ),
      },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, invert, ...rest }) {
  let { currentUser } = useAuth();
  if (invert) return currentUser ? <Navigate to="/app/dashboard" /> : children;
  if (!invert) return currentUser ? children : <Navigate to="/login" />;
}
export default routes;
