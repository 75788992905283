import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Create from "@material-ui/icons/Create";
import { truncate } from "lodash";
import CategoryDialog from "./CategoryDialog";
import { Link as RouterLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CategoryCard = ({ className, category, refresh, ...rest }) => {
  const [openDialog, setDialog] = useState(false);
  const href = `/app/products/${category.id}`;
  function editCategory() {
    setDialog(true);
  }
  function closeDialog() {
    setDialog(false);
  }
  function openCategory() {
    console.log(123);
  }
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {openDialog && (
        <CategoryDialog
          open={openDialog}
          closeDialog={closeDialog}
          editMode
          category={category}
          refresh={refresh}
        />
      )}
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Avatar alt="Product" src={category.image.url} variant="square" />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {category.title}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {truncate(category.description, { length: "100" })}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justify="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <Button onClick={editCategory} style={{ padding: 0 }}>
              {/* <Create className={classes.statsIcon} color="action" /> */}
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                Edit
              </Typography>
            </Button>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Button style={{ padding: 0 }} component={RouterLink} to={href}>
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                View
              </Typography>
              {/* <ArrowForward className={classes.statsIcon} color="action" /> */}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

CategoryCard.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object.isRequired,
};

export default CategoryCard;
