import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import CategoryDialog from "./CategoryDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, refresh, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setDialog] = useState(false);

  function openDialogBox() {
    setDialog(true);
  }
  function closeDialog() {
    setDialog(false);
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {openDialog && (
        <CategoryDialog open={openDialog} closeDialog={closeDialog} refresh={refresh} />
      )}
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid container item xs={12} sm={4} lg={2}>
          <Button color="primary" variant="contained" onClick={openDialogBox} fullWidth>
            Add Category
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
