import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Fab,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import CategoryCard from "./CategoryCard";
import { getCategories } from "src/utils/firebase-utils/firestore-funcs";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CategoryDialog from "./CategoryDialog";
import AddIcon from "@material-ui/icons/Add";
import PageTitle from "src/components/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  addButton: {
    position: "fixed",
    bottom: 30,
    right: 30,
    zIndex: 1001,
  },
}));

const CategoryList = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setDialog] = useState(false);

  function openDialogBox() {
    setDialog(true);
  }
  function closeDialog() {
    setDialog(false);
  }
  function loadData() {
    setLoading(true);
    getCategories().then((res) => {
      setCategories(res);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!categories.length) loadData();
  }, []);

  if (isLoading) {
    return (
      <Page className={classes.root} title="Products">
        <Container maxWidth={false}>
          <Box display="flex" mt={10} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      </Page>
    );
  }
  if (categories.length === 0) {
    return (
      <Page className={classes.root} title="Products">
        <Container maxWidth={false}>
          <Box
            display="flex"
            flexDirection="column"
            mt={10}
            alignItems="center"
            justifyContent="center"
          >
            <HourglassEmptyIcon fontSize="large" />
            <Typography variant="h4" color="textSecondary" align="center" className="my-5">
              No Data to show here, Please add new category using the button above.
            </Typography>
          </Box>
        </Container>
      </Page>
    );
  }
  return (
    <Page className={classes.root} title="Products">
      <PageTitle title="Products" />
      {openDialog && (
        <CategoryDialog open={openDialog} closeDialog={closeDialog} refresh={loadData} />
      )}
      <Container maxWidth={false}>
        <Fab
          className={`my-2 my-sm-0 ${classes.addButton}`}
          aria-label={"add-product"}
          onClick={openDialogBox}
          variant="extended"
          size="large"
          color="primary"
        >
          <AddIcon />
          Add Category
        </Fab>
        <Box mt={3}>
          <Grid container spacing={3}>
            {categories &&
              categories.length > 0 &&
              categories.map((product) => (
                <Grid item key={product.id} lg={4} md={6} xs={12}>
                  <CategoryCard
                    className={classes.productCard}
                    category={product}
                    refresh={loadData}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        {categories.length > 8 && (
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination color="primary" count={3} size="small" />
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default CategoryList;
