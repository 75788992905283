import React from "react";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import CompanyForm from "./CompanyForm";
export default function ComapnyPage() {
  return (
    <Page title="Company settings" className="p-0">
      <PageTitle title="Company settings" />
      <CompanyForm />
    </Page>
  );
}
