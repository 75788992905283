import React from "react";
import Typography from "@material-ui/core/Typography";
import TitleLogo from "./TitleLogo";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  CircularProgress,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import { getCompanyData } from "src/utils/firebase-utils/company-firestore";
import AboutCompany from "./AboutCompany";
import ContactInfo from "./ContactInfo";
import CompanyAddress from "./CompanyAddress";
import CompanyFeatures from "./CompanyFeatures";
import CompanyImages from "./CompanyImages";
import TabPanel, { a11yProps } from "src/utils/components/TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function CompanyForm() {
  const classes = useStyles();
  const [company, setCompany] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    getCompanyData().then((res) => {
      if (res.success) {
        console.log(res, "/company");
        setCompany(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [isLoading]);
  function forceRender() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }
  if (isLoading) {
    return (
      <Grid container justify="center" alignItems="center" className="h-100 ">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="white">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          centered
        >
          <Tab label="Address" {...a11yProps(0)} />
          <Tab label="Contact " {...a11yProps(1)} />
          <Tab label="About" {...a11yProps(2)} />
          <Tab label="Features" {...a11yProps(3)} />
          <Tab label="Images" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CompanyAddress
          data={
            company &&
            company.contact && {
              primaryAddress: company.contact.primaryAddress,
              secondaryAddress: company.contact.primaryAddress,
            }
          }
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContactInfo data={company.contact} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AboutCompany reRender={forceRender} data={company && company.about?.data} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CompanyFeatures reRender={forceRender} data={company && company.features?.data} />{" "}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CompanyImages reRender={forceRender} data={company && company?.about} />
      </TabPanel>
    </div>
  );
}
