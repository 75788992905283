import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Formik } from "formik";
import React, { useState } from "react";
import PromtDialog from "src/utils/components/PromtDialog";
import { addSubCategory, deleteSubcategory } from "src/utils/firebase-utils/firestore-funcs";
import * as Yup from "yup";

export default function SubCategoryDialog(props) {
  const { open, closeDialog, category, refresh, ...rest } = props;
  const [name, setName] = useState("");
  const [promt, setPromt] = useState(false);
  const [promtInfo, setPromtInfo] = useState("");
  const [isLoading, setLoading] = useState(false);

  function handlePromtClose() {
    setPromt(false);
  }
  function handleSubDelete(name) {
    setPromtInfo({
      title: "Warning",
      message:
        "Deleting sub category will also delete all the products associated with the sub category",
      btn1: "Cancel",
      btn2: "Delete",
      btn2Click: () => {
        deleteSub(name);
      },
      warning: true,
    });
    return setPromt(true);
  }
  function deleteSub(name) {
    setPromt(false);
    setLoading(true);
    deleteSubcategory(name, category).then((res) => {
      if (!res.success) {
        setPromtInfo({
          title: "Error",
          message: res.message,
          btn1: "",
          btn2: "Try Again",
          btn2Click: () => {
            handlePromtClose();
          },
          error: true,
        });
        setLoading(false);
        return setPromt(true);
      }
      setPromtInfo({
        title: "Success",
        message: res.message,
        btn1: "",
        btn2: "Ok",
        btn2Click: () => {
          refresh();
          handlePromtClose();
          closeDialog();
        },
        success: true,
      });
      setLoading(false);
      return setPromt(true);
    });
  }
  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={"sm"} fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={closeDialog}>
        Manage Subcategories
      </DialogTitle>
      {category.sub.length > 0 && (
        <DialogContent dividers>
          {category &&
            category.sub.length > 0 &&
            category.sub.map((item) => {
              return (
                <div
                  className="d-flex w-100 justify-content-between align-items-center text-capitalize"
                  key={item}
                >
                  <Typography variant="caption">{item}</Typography>
                  <Button onClick={() => handleSubDelete(item)}>
                    <Close />
                  </Button>
                </div>
              );
            })}
        </DialogContent>
      )}

      {/* ADD NEW */}
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(100).required("Sub category Name is required"),
        })}
        onSubmit={(values, actions) => {
          const subCategories = [...category.sub, values.name.toLowerCase()];
          addSubCategory(category.id, subCategories)
            .then((res) => {
              if (!res.success) {
                setPromtInfo({
                  title: "Error",
                  message: res.message,
                  btn1: "",
                  btn2: "Try Again",
                  btn2Click: () => {
                    handlePromtClose();
                  },
                  error: true,
                });
                actions.setSubmitting(false);
                return setPromt(true);
              }
              setPromtInfo({
                title: "Success",
                message: res.message,
                btn1: "",
                btn2: "Ok",
                btn2Click: () => {
                  refresh();
                  handlePromtClose();
                  closeDialog();
                },
                success: true,
              });
              actions.setSubmitting(false);
              return setPromt(true);
            })
            .catch((err) => {
              console.log(err);
              actions.setSubmitting(false);
            });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <PromtDialog open={promt} dialogInfo={promtInfo} handleClose={handlePromtClose} />
            {isSubmitting || isLoading ? (
              <Backdrop open={isSubmitting || isLoading} style={{ zIndex: 1001 }}>
                <CircularProgress />
              </Backdrop>
            ) : null}
            <DialogContent dividers>
              <Typography variant="h4" color="textSecondary">
                Add new
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  value={values.name}
                  InputProps={{ style: { maxHeight: "40px" } }}
                  onChange={handleChange}
                  name="name"
                  fullWidth
                  label="Subcategory name"
                  className="d-flex align-items-center p-2"
                  variant="outlined"
                  margin="normal"
                  type="text"
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button fullWidth size="large" type="submit" variant="contained" color="primary">
                  Add
                </Button>
              </Box>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
