import {
  Card,
  CardContent,
  Fab,
  InputAdornment,
  makeStyles,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Search as SearchIcon } from "react-feather";
import AddProductDialog from "./AddProductDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    position: "fixed",
    bottom: 30,
    right: 30,
    zIndex: 1001,
  },
  editSubBtn: {
    position: "fixed",
    bottom: 30,
    right: 220,
    zIndex: 1001,
  },
  responsive: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
}));

const Toolbar = ({ className, refresh, category, handleSearch, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setDialog] = useState(false);

  return (
    <div className={` ${clsx(classes.root, className)}`} {...rest}>
      <Card>
        <CardContent className="d-flex flex-column-reverse flex-sm-row justify-content-around align-items-center p-2">
          <TextField
            fullWidth
            className="mx-2"
            onKeyUp={handleSearch}
            InputProps={{
              style: { maxHeight: "40px" },
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search products by name or stock ID"
            variant="outlined"
          />
        </CardContent>
      </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
