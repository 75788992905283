import { Button, Fab } from "@material-ui/core";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import React from "react";

export default function ImageUploader(props) {
  const { removeImage, handleUpload, image, inputProps, editMode } = props;
  return (
    <div className={` p-3 d-flex flex-column align-item-center justify-content-center`}>
      <div className="p-2 h-100 position-relative border d-flex align-items-center justify-content-center">
        {image && image.url ? (
          <img
            id="target"
            src={image.url}
            className="img-fluid"
            style={{
              minHeight: "140px",
              objectFit: "contain",
            }}
          />
        ) : null}
        {editMode && image && image.url && (
          <Button
            onClick={removeImage}
            disableFocusRipple
            style={{ position: "absolute", bottom: 0, left: 0 }}
            className="text-danger"
          >
            Remove
          </Button>
        )}
        {(image && !image.url) || !image ? (
          <div
            style={{ minHeight: "140px" }}
            className="d-flex align-items-center justify-content-center"
          >
            {editMode && (
              <input
                accept="image/*"
                className={`d-none`}
                id="contained-button-file"
                multiple
                name="image"
                type="file"
                onChange={handleUpload}
                {...inputProps}
              />
            )}
            <label htmlFor="contained-button-file">
              <Fab component="span">
                <AddPhotoAlternateIcon />
              </Fab>
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
}
