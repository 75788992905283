import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function DetailItem({ title, data }) {
  return (
    <Grid item xs={12} container alignItems="center" className="my-2">
      <Grid item xs={6} sm={4}>
        <Grid item>
          <Typography>{title}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8}>
        <Grid item>
          <Typography>{data || " - - "}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function QueryDialog({ query, onClose, ...props }) {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog onClose={onClose} fullWidth maxWidth="md" fullScreen={isSmallDevice} {...props}>
      <DialogTitle>Query Details</DialogTitle>
      <DialogContent dividers>
        <Grid container alignItems="center" className="p-2">
          <Typography variant="h3" className="mb-2">
            Enquiry details
          </Typography>

          <DetailItem title={"Name"} data={query.name} />
          <DetailItem title={"Contact"} data={query.phone} />
          <DetailItem title={"Message"} data={query.message} />
          <DetailItem title={"Email"} data={query.email} />
          <DetailItem title={"Company"} data={query.company} />
          <DetailItem title={"Country"} data={query.country} />
          <DetailItem title={"City"} data={query.city} />

          {query.products.length ? (
            <>
              <Typography variant="h3" className="mb-3 mt-3">
                Enquired products
              </Typography>
              <Grid container>
                <ProductsTable products={query.products} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function ProductsTable(props) {
  const { products } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product Title</TableCell>
            <TableCell align="right">Stock ID</TableCell>
            <TableCell align="right">Category</TableCell>
            <TableCell align="right">Sub Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell component="th" scope="row">
                {product.title}
              </TableCell>
              <TableCell align="right">{product.stockId}</TableCell>
              <TableCell align="right">{product.category}</TableCell>
              <TableCell align="right" className="text-capitalize">
                {product.subCategory}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
