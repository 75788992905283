import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import { CheckCircle, RemoveCircle, Warning } from "@material-ui/icons";
import React from "react";

export default function PromtDialog(props) {
  const { handleClose, open, dialogInfo, ...restProps } = props;
  const themes = useTheme();

  function button2Click() {
    if (dialogInfo.btn2Click) dialogInfo.btn2Click();
  }
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={dialogInfo.handleClose ? dialogInfo.handleClose : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...restProps}
    >
      <DialogTitle id="alert-dialog-title ">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" component="h3">
            {dialogInfo.title ? dialogInfo.title : ""}
          </Typography>
          {dialogInfo.warning && <Warning fontSize="large" className="text-warning" />}
          {dialogInfo.success && (
            <CheckCircle fontSize="large" color="action" className="text-success" />
          )}
          {dialogInfo.error && <RemoveCircle fontSize="large" className="text-danger" />}
        </Box>
      </DialogTitle>

      <DialogContent className="">
        <div id="alert-dialog-description">
          <Typography variant="body1" component="h3">
            {dialogInfo.message ? dialogInfo.message : ""}
          </Typography>
        </div>
        <Typography variant="h5" component="h5">
          {dialogInfo.content ? dialogInfo.content : ""}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {dialogInfo.btn1}
        </Button>
        <Button onClick={button2Click} color="primary" autoFocus>
          {dialogInfo.btn2}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
